// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Floating Header
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.floating-header {
  @media #{$medium-up} {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
  }
}
