// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Cookie Disabled Pop-up
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.cookie-popup {
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2147483640;
  background-color: $black-alpha;
  color: $white;
  padding: 1.5em;
}

@media #{$small-only} {
  .cookie-popup > .row > .medium-2 {
    padding-top: 1em;
  }
}
