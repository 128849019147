.row.doctor-info__row {
  margin-top: 1rem;
  display: inline-flex;

  .panel {
    height: 100%;
  }
}

.doctor-info__picture {
  border-radius: 50%;
}

.doctor-info__title {
  margin-top: 1rem;
  @media #{$medium-up} {
    padding-left: 0;
  }
}

.doctor-info__description {
  margin-top: 2rem;
}
