// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Shopper Approved overridden css styles
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

#outer_shopper_approved {
  #shopper_approved {
    border: 1px solid rgba(19, 21, 22, 0.25);
    border-radius: 0.5rem;
    background-color: #efefef;
  }

  #shopper_approved #sa_outer {
    max-width: 100% !important;
  }

  #shopper_approved #sa_header_img {
    display: none !important;
  }

  #shopper_approved #sa_header {
    background: #43ac6a !important;
    border-bottom: 1px solid #c9cbcc !important;
    border-radius: 0.4375rem 0.4375rem 0 0 !important;
    color: #fff !important;
    line-height: 1 !important;
    padding: 20px 0 15px 30px !important;
    font-size: 27px !important;
  }

  #shopper_submit {
    font-weight: bold !important;
    background-color: #43ac6a !important;
    color: #fff !important;
    text-indent: 0 !important;
    background-image: none !important;
  }

  @media (max-width: 500px) {
    #shopper_approved #sa_header {
      font-size: 1.6rem !important;
      margin-left: -10px;
      margin-right: -10px;
      margin-top: -10px;
      width: auto;
    }
  }
}
