// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// # Variables
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//
// ## Description:
// Variables are reusable pieces of data that can be used
// throughout the entire project.
//
// ## Table of Contents:
// 1. Colors
//
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Colors:
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Warm
$light-oragne: #ff9834;
$orange: #f96d21;
$yellow: #f6e896;

// Cool
$lighter-blue: #ebf4f9;
$light-blue: #bbd9ee;
$blue: #54a4de;
$dark-blue: #256ca2;
$darker-blue: #1d558e;
$darkest-blue: #164970;

$green: #06b43b;
$dark_green: #388f58;

$purple: #b4067f;

// Grey Scale
$white: #ffffff;
$light-grey: #e9e9e9;
$lighter-grey: #c9cbcc;
$grey: #cccccc;
$dark-grey: #4c4c4c;
$black: #071724;
$black-alpha: #071724d0;

//alert
$light-alert: #ffdfdf;

// Fonts
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

$arial: Arial, sans-serif;
