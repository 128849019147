// HTML
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
html {
  background: url(../images/bg.gif);
  font-family: $arial;
  word-break: break-word;
}

// Body
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
body {
  background: url(../images/bg--gradient.gif);
  background-repeat: repeat-x;
  height: auto;
  min-height: 100%;
}

// Container
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.container {
  background: $white;
  max-width: $row-width;
  border-color: $grey;
  border-style: solid;
  border-width: 0 1px;
  z-index: 10;
  @media #{$large-up} {
    margin: 0 auto;
    // border-radius: $global-radius;
  }
}

// Header
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.header {
  background: rgba($white, 0.9);
  padding: rem-calc(5px);
}

.header__flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo {
  @media #{$medium-up} {
    min-width: 340px;
  }

  img {
    max-height: 65px;
  }
}

.healthlabs-logo {
  max-height: 100%;
  max-width: 100%;
}

// Site Title
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.site-title {
}

.site-title__icon {
  width: rem-calc(45);
  height: auto;
  float: left;
  margin-right: rem-calc(10);
}

.site-title__name {
  margin: 0;
  line-height: rem-calc(24);
  font-size: rem-calc(40);
  color: #2a6290;
  margin-top: rem-calc(12);
  span {
    color: $success-color;
  }
}

.site-title__slogan {
  color: #666767;
  font-size: rem-calc(17);
  font-weight: bold;
  margin-bottom: rem-calc(6);
}

// Tab Bars (Off Canvas)
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.tab-bar-section {
  @media #{$small-only} {
    padding: 0;
  }
}

.tab-bar__logo {
  height: 30px;
  margin-top: -7px;
  margin-right: 10px;
  width: auto;
}

.tab-bar--cart {
  text-align: center;
  display: block;
  height: rem-calc(45);
  padding: rem-calc(10) 0;
  background: $orange;
  background: linear-gradient($orange, darken($orange, 8%));
  color: $white;
  border-bottom: 1px solid darken($orange, 12%);
  &:hover {
    color: $white;
  }
}

.tab-bar--cart__spacing {
  @media #{$small-only} {
    margin-top: rem-calc(45);
  }
}

.tab-bar__icon,
.tab-bar__icon:focus,
.tab-bar__icon:hover {
  color: $white;
  font-size: $tabbar-height/2;
  width: $tabbar-height * 1.5;
  height: $tabbar-height;
  padding: rem-calc(5) rem-calc(10) 0;
  text-align: center;
}

.tab-bar__icon::after {
  color: $white;
  content: 'menu';
  display: block;
  font-size: rem-calc(12);
}

.tab-bar--phone {
  background: $lighter-blue;
  text-align: center;
  border-bottom: 1px solid $hr-border-color;
  // margin-top: rem-calc(45);
}

.tab-bar--phone__link {
  padding: rem-calc(10) 0;
}

.tab-bar--phone__text {
  font-style: italic;
  font-size: rem-calc(12);
}

.tab-bar--top {
  padding: rem-calc(10) 0;
  display: block;
  background: darken($blue, 10%);
  color: lighten($blue, 20%);
  text-align: center;
  &:hover {
    color: lighten($blue, 20%);
  }
}

.mobile-menu {
  font-size: 12px;
  margin: 0;
  padding: 0;
  padding-left: rem-calc(10);
  position: absolute;
  bottom: 0;
  left: 0;
  line-height: 1;
}

// .edit-width {
//   width: 3.8rem;
//   text-align: center;
// }

// Modal (Popup)
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.reveal-modal {
  border: none;

  &.open {
    display: block;
    opacity: 1;
    visibility: visible;
    top: 100px;
  }
}

.reveal-modal-bg {
  &.open {
    display: block;
  }
}

.reveal-modal > :first-child,
.reveal-modal > :last-child,
.modal-popup__container {
  @media #{$small-only} {
    margin: rem-calc(20);
  }
}

.modal-popup__container {
  box-shadow: 0 0 10px rgba($black, 0.4);
}

.close-reveal-modal {
  @media #{$small-only} {
    margin: rem-calc(14);
  }
}

// Hero
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.hero {
  background: url(../images/hero.jpg) no-repeat bottom right #d8c5b7;
  background-size: cover;
  min-height: 475px;
  border-bottom: 1px solid $hr-border-color;
  border-radius: $global-radius;
  border: 1px solid #603c38;
  padding: $column-gutter;
  .fa-ul {
    margin-bottom: 0;
  }
}

.hero__text {
  margin-top: rem-calc(50);
  width: 60%;
}

.hero__title {
  border-top: 5px solid $primary-color;
  padding-top: 10px;
  font-size: rem-calc(37);
  color: $white;
  line-height: 1;
  text-shadow: 2px 2px 2px rgba($black, 0.5);
}

.hero__subtitle {
  border-bottom: 5px solid $primary-color;
  padding-bottom: 10px;
  font-size: rem-calc(32);
  color: $white;
  text-shadow: 2px 2px 2px rgba($black, 0.5);
  font-style: italic;
  font-weight: bold;
  text-transform: lowercase;
  line-height: 1;
  margin-bottom: rem-calc(10);
}

.hero__list {
  color: $white;
  text-shadow: 2px 2px 2px rgba($black, 0.5);
}

// Map Panel
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.map-panel {
  background-image: url(../images/test-locations.png);
  background-position: center center;
  background-size: cover;
  min-height: 180px;
  @media #{$medium} {
    min-height: 400px;
  }
  margin-top: rem-calc(30);
  border-radius: $global-radius;
  border: 1px solid #2679c7;
}

.map-panel__box {
  background: rgba($white, 0.85);
  border: 1px solid $primary-color;
  padding: rem-calc(20);
  margin: 0 auto;
  max-width: 400px;
  border-radius: $global-radius;
  @media #{$small-only} {
    margin: rem-calc(20);
    margin-top: rem-calc(30);
  }
  @media #{$medium-up} {
    margin-top: rem-calc(125);
  }
}

.map-panel__title {
  color: $primary-color;
  text-shadow: 2px 2px 2px rgba($white, 1);
}

.map__empty {
  margin: rem-calc($column-gutter);
  text-align: center;
}

.map__empty-arrow {
  padding-top: rem-calc(30);
}

.map__empty-text {
  padding: rem-calc(20) rem-calc(10) 0;
}

input[type='text'].map-panel__input {
  margin: 0;
}

// Features
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.features__title {
  color: $orange;
  text-transform: uppercase;
  font-size: rem-calc(22);
}

// Content
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.content {
}

.main {
  padding: 0.5rem;

  @media #{$medium-up} {
    padding: $column-gutter/2;
  }
}

.sidebar {
  padding: 0.5rem;

  @media #{$medium-up} {
    padding: $column-gutter/2;

    &.columns {
      padding-right: 0;
    }
  }
}

// Footer
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.footer {
  background: linear-gradient($darker-blue, $dark-blue);
  border-bottom: 1px solid $darker-blue;
  padding: $column-gutter/2 0;
}

.footer__title {
  font-size: rem-calc(18);
  color: $snow;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 12px;
}

.footer__menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.2;
  @media #{$small-only} {
    columns: 2;
  }
  li a {
    color: lighten($blue, 20%);
    text-decoration: none;
    margin-bottom: rem-calc(10);
    display: inline-block;
  }
}

.footer__menu--categories {
  @extend .footer__menu;
  column-count: 2;
}

.footer__menu-item--all {
  color: $dark-blue;
  text-decoration: none;
}

.footer__categories {
  @media #{$small-only} {
    padding-top: $column-gutter/4;
  }
}

.footer__text {
  color: $white;
}

.footer__link {
  color: lighten($blue, 20%);
  text-decoration: underline;
}

.footer__hr {
  height: 30px;
  border-style: solid;
  border-color: $light-grey;
  border-width: 1px 0 0 0;
  border-radius: 20px;
  width: 80%;
  margin: 1.25rem 0 0rem;

  &:before {
    display: block;
    content: '';
    height: 30px;
    margin-top: -31px;
    border-style: solid;
    border-color: $light-grey;
    border-width: 0 0 1px 0;
    border-radius: 20px;
  }
}

.footer__badge {
}

.footer__badge-img {
  @media #{$medium-up} {
    padding-top: rem-calc(40);
  }
}

.footer a:hover,
.footer a:focus {
  color: $orange;
}

// Copyright
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.copyright {
  background: $darker-blue;
  background: linear-gradient($darker-blue, darken($darker-blue, 10%));
  border-top: 1px solid darken($blue, 20%);
  color: $light-blue;
  font-size: rem-calc(12);
  letter-spacing: 1px;
  padding: rem-calc(10);

  @media #{$small-only} {
    padding-bottom: 5rem;
    text-align: center;
  }

  @media #{$medium-up} {
    font-size: rem-calc(14);
  }

  a {
    color: $light-blue;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: $orange;
    }
  }
}

// Utilities
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.margin-bottom-none {
  margin-bottom: 0 !important;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.medium-right {
  @media #{$medium-up} {
    text-align: right;
  }
}

.alert-color {
  color: $alert-color;
}

.padding-none {
  padding: 0;
}

.loading-spinner {
  padding-top: 20%;
  text-align: center;
  color: #1d558e;
  & span {
    font-family: 'Open Sans', Helvetica, Roboto, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.33333em;
    line-height: 0.75em;
    text-rendering: optimizeLegibility;
    vertical-align: 50%;
  }
}

// Title
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.content__title {
  text-transform: uppercase;
  padding: 10px 10px 10px 0;
  display: inline;
  font-size: rem-calc(30);

  @media #{$medium} {
    background: $white;
    font-size: rem-calc(44);
  }
}

.content__title-wrap {
  margin-bottom: 20px;
  @media #{$medium} {
    background: url(../images/separator-title.gif) 0 50% repeat-x;
  }
}

.content__title--about {
  display: block;
}

.content__link--main {
  display: block;
  padding: rem-calc(5) 0 rem-calc(40) rem-calc(20);
}

.panel__subtitle {
  margin-top: rem-calc(20);
  &.orange a {
    color: $orange;
    &:hover {
      color: darken($orange, 10%);
    }
  }
}

.test-menu-item {
  border-bottom: 1px solid $white;
  padding-bottom: rem-calc(5);
  margin-bottom: rem-calc(10);
}

// Panel
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.panel {
  background: $silver;
  border-radius: 0 0 $global-radius $global-radius;
  border: 1px solid $grey;
  padding: $column-gutter/2;
}

// to override the radio button style from findlab plugin since non radio button can't pass behat test
.panel .findalab__dayFilter {
  input[type='radio'] {
    visibility: visible;
    top: 3px;
    left: -2px;
  }
  .findalab__radioButton {
    visibility: hidden;
  }
}

.panel--cta {
  @include panel($bg: $dark-blue, $padding: $column-gutter, $adjust: true);
  border-radius: $global-radius;

  // to override p tags generated by CMS redactor
  p:last-child {
    margin-bottom: 0;
  }
}

.panel__title {
  background: $blue;
  color: $white;
  line-height: 1;
  padding: $column-gutter/4 $column-gutter/2;
  margin-bottom: 0;
  border-radius: $global-radius $global-radius 0 0;

  @media #{$small-only} {
    font-size: 1.6rem;
  }
}

.panel__title--success {
  @extend .panel__title;
  background: $success-color;
}

.panel__title--alert {
  @extend .panel__title;
  background: $alert-color;
}

.panel__title--warning {
  @extend .panel__title;
  background: $warning-color;
}

.panel__title--orange {
  @extend .panel__title;
  background: $orange;
}

.panel__description-title {
  font-style: italic;
  color: $blue;
  text-align: center;
}

.panel__description {
  margin-bottom: $column-gutter/2;
  text-align: center;
  margin-bottom: 0;
}

// Sidenav
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.side-nav {
  i {
    padding-top: rem-calc(5);
  }
  // Nested
  .side-nav {
    background: lighten($secondary-color, 5%);
  }
}

.side-nav__highlight {
  background-color: $lighter-blue;
  background: linear-gradient($lighter-blue, darken($lighter-blue, 8%));
}

// Widgets
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.widget {
  background: $snow;
  border: 1px solid $light-blue;
  border-radius: $global-radius;
  margin-bottom: $column-gutter/2;
  &:last-child {
    margin-bottom: 0;
  }
  .button {
    margin-bottom: 0;
  }
  &.cart {
  }
}

.widget__title {
  background: $light-blue;
  background: linear-gradient($light-blue, $snow);
  border-radius: $global-radius $global-radius 0 0;
  font-weight: bold;
  text-transform: uppercase;
  padding: rem-calc(6 10 6);
  color: $darkest-blue;
}

.widget__padding {
  padding: rem-calc(14);
}

.widget__img {
  max-width: 150px;
}

.widget__input {
  padding: rem-calc(6);
  font-size: rem-calc(14);
}

input.widget__input:focus {
  outline: none;
}

.widget--location {
  @extend .widget;
  border-color: lighten($success-color, 20%);
  .widget__title {
    background: lighten($success-color, 30%);
    background: linear-gradient(lighten($success-color, 30%), $snow);
    color: darken($success-color, 7%);
  }
}

.widget--orange {
  @extend .widget;
  border-color: lighten($orange, 20%);
  .widget__title {
    background: lighten($orange, 30%);
    background: linear-gradient(lighten($orange, 30%), $snow);
    border-bottom: none;
    color: darken($orange, 7%);
  }
}

.widget--cta {
  @include panel($bg: $dark-blue, $padding: $panel-padding, $adjust: true);
  border-radius: $global-radius;
}

// Hide the "proceed to checkout" button on order page
.order-page .button--order {
  display: none;
}

.widget--cards {
  @extend .widget;
  border-color: lighten($jumbo, 20%);
  .widget__title {
    background: lighten($jumbo, 30%);
    background: linear-gradient(lighten($jumbo, 30%), $snow);
    color: darken($jumbo, 7%);
  }
}

.widget__item {
  height: auto;
  margin-bottom: rem-calc(5);
  width: 23%;
}

.widget__item--alt {
  @extend .widget__item;
  border-radius: 5px;
  width: 50%;
}

.widget--norton {
  @extend .widget;
  border-color: lighten(#f0a302, 20%);
  .widget__title {
    background: lighten(#f0a302, 30%);
    background: linear-gradient(lighten(#f0a302, 30%), $snow);
    color: darken(#f0a302, 7%);
  }
}

.widget__link {
  color: $success-color;
  font-size: rem-calc(14);
  text-decoration: underline;
  &--orange {
    @extend .widget__link;
    color: $orange;
  }
}

// Cart
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.cart--empty {
  margin-bottom: 0;
}

.cart {
  width: 100%;
}

.cart-item__title {
  word-break: break-word;
  -ms-word-break: break-all;
  overflow-wrap: break-spaces;
  word-wrap: break-spaces;
}

@supports (-ms-ime-align: auto) {
  .cart-item__title {
    word-break: break-all;
    -ms-word-break: break-all;
    word-wrap: break-word;
  }
}

.cart-item__description {
  width: 100%;
}

.cart-item__price {
  font-style: italic;
  text-align: right;
  width: 40%;
  min-width: 71px;
}

.cart-item__remove {
  padding: 0;
  width: 5%;
}

.cart-item__remove-link {
  color: $alert-color;
  display: block;
  &:hover,
  &:focus,
  &:active {
    color: darken($alert-color, 15%);
  }
  font-size: 13px;
  font-style: italic;
}

.cart__total {
  @extend .clearfix;
  font-weight: bold;
  margin-bottom: rem-calc(15);
  padding: rem-calc(8) rem-calc(6);
}

.cart__total-title {
  float: left;
}

.cart__total-price {
  float: right;
  font-style: italic;
}

.cart__accepted {
  text-align: center;
  padding-top: rem-calc(5);
}

.cart__overflow {
  height: auto;
  max-height: 300px;
  overflow: auto;
}

// Lists
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.fa-check {
  color: $success-color;
}

.fa-ul p {
  margin-bottom: 0;
}

// Test
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.test__price {
  background: $white;
  border-radius: $global-radius;
  padding-top: rem-calc(10);
  border: 1px solid $grey;
  .button {
    margin-bottom: 0;
  }
}

.test__title {
  font-size: rem-calc(40);
  strike {
    color: $grey;
  }
  b {
    color: $dark_green;
  }
}

// Add to Cart
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.add-to-cart {
  border: 1px solid $grey;
  border-radius: $global-radius;
  background: $white;
  b {
    color: $dark_green;
  }
}

.add-to-cart__price {
  font-size: rem-calc(20);
  padding: rem-calc(12) 0;
  text-align: center;
  &--tiny {
    font-size: rem-calc(11);
    padding: rem-calc(10) 0;
    text-align: center;
  }
}

// JS
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.js-hide {
  display: none !important;
}

.js-show {
  display: block !important;
}

// Disabled Button
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
@keyframes disabled {
  0% {
    background: $green;
    border-color: $green;
    opacity: 1;
  }
  80% {
    background: lighten($green, 10%);
    border-color: lighten($green, 10%);
    opacity: 0.8;
  }
}

.js__add-animation {
  animation-name: disabled;
  animation-duration: 800ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

// Test - Description Area
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.test__headline {
}

.test__text {
}

.test__image {
  border-radius: $global-radius;
  border: 1px solid $grey;
  background: $silver;
  padding: rem-calc(4);
  img {
    border-radius: $global-radius/2;
  }
  &--right {
    @extend .test__image;
    float: right;
    margin: 0 0 rem-calc(15) rem-calc(15);
  }
  &--left {
    @extend .test__image;
    float: left;
    margin: 0 rem-calc(15) rem-calc(15) 0;
  }
  &--center {
    @extend .test__image;
  }
}

.test__image-caption {
  text-align: center;
  padding: rem-calc(4) 0;
  font-size: rem-calc(14);
  font-style: italic;
}

.test__quote {
  &--right {
    float: right;
    margin: 0 0 rem-calc(15) rem-calc(15);
    border-right: 0;
    border-left: $blockquote-border;
  }
  &--left {
    float: left;
    margin: 0 rem-calc(15) rem-calc(15) 0;
    border-right: $blockquote-border;
    border-left: 0;
  }
}

// Map
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.map {
  &:before {
    margin-left: rem-calc(40);
    @include css-triangle(rem-calc(20), $white, bottom);
  }
}

.map__results {
  background: $white;
  padding: rem-calc(10) !important;
  max-height: 575px;
  min-height: 575px;
  overflow-y: scroll;
}

.map__results-title {
}

.map__result {
  border-width: 1px 1px 0;
  border-style: solid;
  border-color: $hr-border-color;
  padding: rem-calc(10);
  transition: 0.4s;
  &:hover {
    background: $silver;
  }
  &:last-child {
    border-bottom-width: 1px;
  }
}

.result {
}

.result__address {
  font-weight: bold;
}

.result__meta {
  span {
    color: $info-color;
  }
}

.result__button {
  margin-top: rem-calc(10);
}

// Top Bar
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.top-bar-section li:not(.has-form) a:not(.button),
.top-bar-section .has-form,
.contain-to-grid,
.top-bar {
  background: $dark-blue;
  background: linear-gradient($dark-blue, $darker-blue);
}

.top-bar-section li:not(.has-form) a:not(.button):hover,
.top-bar-section li.active:not(.has-form) a:not(.button):hover {
  background: #1d558e;
  background: linear-gradient(#1d558e, #256ca2);
}

.top-bar-section li.active:not(.has-form) a:not(.button) {
  background: #1d558e;
  background: linear-gradient(#1d558e, #256ca2);
  border-top: 3px solid #43ac6a;
  margin-top: -3px;
}

.top-bar-section .dropdown li:not(.has-form):not(.active) > a:not(.button) {
  border: none;
  margin: 0;
}

.top-bar .button {
  line-height: 0;
}

.top-bar__search {
  display: none;
  width: 330px;

  @media (min-width: 1125px) {
    display: block;
  }
}

// Cards
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.cards {
  margin-bottom: rem-calc(10);

  img {
    height: 26px;
    width: auto;

    @media (min-width: 375px) {
      height: 30px;
    }
  }
}

// Credit Card Field
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
#credit-card-number {
  background-image: url('../images/payment/cc-back.svg'), url('../svg/checkmark-muted.svg');
  background-position: rem-calc(6) center, right rem-calc(6) center;
  background-repeat: no-repeat, no-repeat;
  background-size: (16px * 2) auto, (8px * 2) auto;
  padding: 0 46px;

  &.cc-valid {
    border-color: $success-color;
  }
}

$credit-cards: amex, visa_electron, visa, mastercard, discover;
@each $card in $credit-cards {
  #credit-card-number.#{$card} {
    background-image: url('../images/payment/cc-#{$card}.svg'), url('../svg/checkmark-muted.svg');

    &.cc-valid {
      background-image: url('../images/payment/cc-#{$card}.svg'), url('../svg/checkmark.svg');
    }
  }
}

label.inline {
  margin-bottom: 0;
}

// Popup
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.popup__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  line-height: 1.2;
  column-count: 2;
  @media #{$medium} {
    column-count: 3;
  }
  @media #{$large} {
    column-count: 4;
  }
  & > li {
    margin-bottom: rem-calc(16);
  }
}

// Buttons
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
@mixin fancy-button($color) {
  background: linear-gradient($color, darken($color, 8%));
  border: 1px solid lighten($color, 10%);
  border-bottom: none;
  // box-shadow: 0 0 0 1px rgba($color,1);
  &:hover:enabled,
  &:focus:enabled {
    background: linear-gradient(darken($color, 8%), $color);
  }
}

.button {
  @include fancy-button(lighten($orange, 5%));
  &.info {
    @include fancy-button($info-color);
  }
  &.secondary {
    @include fancy-button($secondary-color);
  }
  &.success {
    @include fancy-button($success-color);
  }
}

// Simple Cart Checkout
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.js__simple-cart__icon {
  margin-right: rem-calc(10);
}

.js__simple-cart__quantity {
  font-size: rem-calc(9);
  width: rem-calc(15);
  height: rem-calc(15);
  padding-top: rem-calc(1);
  text-align: center;
  border-radius: 50%;
  background: $success-color;
  position: absolute;
  top: rem-calc(9);
  margin-left: rem-calc(-20);
}

.cart .coupon td {
  color: $purple;
}

// Breadcumb
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.breadcrumbs {
  @media #{$small-only} {
    display: none;
  }
}

// Alert Boxes
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.alert-box {
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: $white;
  }

  &.coupon {
    background-color: $purple;
    border: none;
    font-size: 1rem;
    padding: 0.6rem;
    margin-left: -1px;
    margin-right: -1px;
    margin-bottom: 0;

    a {
      color: $white;
      text-decoration: underline;
    }

    &.coupon-small-screen-large-text {
      @media #{$small-only} {
        font-size: 0.14em;
      }
    }

    @media #{$small-only} {
      height: 4.1em;
    }
  }

  &.password-reset {
    background-color: #43ac6a;
    border: none;
  }
}

.private-group-login-alert {
  margin-top: 1.25rem;
}

// Mcafee Icon
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
#pd-float-tm {
  @media #{$small-only} {
    margin-bottom: 55px !important;
  }
}

// Labels in checkbox
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.checkbox-label {
  margin-left: 5px;
}

// Cart Message Alert Box
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.alert-box.alert-box--cart-message {
  background-color: $blue;
  border-color: darken($purple, 10%);
}

// Coupon Alert Box
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.alert-box.alert-box--coupon {
  background-color: $purple;
  border-color: darken($purple, 10%);
  & a {
    color: $white;
    text-decoration: underline;
  }
}

// Alert Box Trumps
.alert-box p:last-child {
  margin-bottom: 0;
}

// Font Awesome Trumps
.fa-ban {
  color: $alert-color;
}

// Global Search Box
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.global-search-box {
  padding: 1rem 1rem 0;
}

// Postfix Trumps
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.postfix--small {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

// Findalab
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.findalab {
  margin-top: 30px;
}
// @TODO: add these defaults to findalab
.findalab__search__user-location {
  &:hover,
  &:focus {
    background: transparent;
    color: #3398db;
  }
}

.findalab__search__day-filter input {
  margin-bottom: 0;
}

// Test Questions Accordion
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.test__questions .accordion {
  border-top: 1px #ebf4f9 solid;
  border-bottom: 1px #ebf4f9 solid;

  .accordion-item {
    border-top: 1px #ebf4f9 solid;

    a {
      background: none;
      font-weight: bold;
    }
  }

  //The accordion is closed
  .accordion-icon:before {
    font-family: FontAwesome;
    content: '\f0d7';
  }

  //The accordion is open
  .active .accordion-icon:before {
    font-family: FontAwesome;
    content: '\f0da';
  }
}

//Badge Generator
//- - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// For the badgeGenerator section.
.code {
  font-family: Consolas, 'Liberation Mono', Courier, monospace;
  font-weight: normal;
  color: $black;
  background-color: $lighter-grey;
  border: 1px solid $dark-grey;
  padding: 0.125rem 0.3125rem 0.0625rem;
}

// Misc
//- - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Pad active accordion items at the bottom slightly to hack around an off-by-one error when asserting viibility of
// the content within in automated testing.
.accordion-item.active {
  padding-bottom: 1px;
}

// Add the same styling to h3 items as the parent anchor tag whenever surrounding text in h3
.accordion dd > a > h3 {
  color: #222222;
  display: inline-block;
  font-family: 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  font-size: 1rem;
}

// Checklist for homepage and Product Page
div.checklist {
  ul {
    @extend .fa-ul;

    li {
      &::before {
        @extend .fa-li;
        @extend .fa;
        @extend .fa-check;
        content: '\f00c';
      }
    }
  }
}

// Give the cart drop down some room to breath if there is limited height
@media only screen and (max-height: 600px) {
  .f-dropdown {
    &.small {
      max-width: 450px !important;
    }
  }
}

.text-highlight {
  background-color: #ffff03;
}

.cvv-input {
  width: 50% !important;
}

div.cvv-info {
  margin-top: 0.5rem;
  i {
    position: absolute;
  }
  i.fa-circle {
    color: #0085de;
    font-size: 1.5rem;
    &:hover {
      color: #0175c4;
      cursor: pointer;
    }
  }
  i.fa-info {
    pointer-events: none;
    color: #ffffff;
    margin-left: 0.45rem;
    margin-top: 0.25rem;
  }
  div.panel {
    display: none;
    position: absolute;
    top: -0.01rem;
    left: 2.5rem;
    z-index: 10;
    border-radius: 6px;
    background-color: #adb5c8;
    width: 9rem;
    div.panel-arrow {
      position: absolute;
      left: -1rem;
      top: 0.2rem;
      svg {
        width: 20px;
        height: 18px;
        polygon {
          fill: #adb5c8;
        }
      }
    }
  }
}

.trustedsite-badge {
  width: 120px;
  height: 50px;
  display: inline-block;
  background-image: url('../images/trustedsite-badge.svg');
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
}

.font-weight-normal {
  font-weight: normal;
}

.mobile-menu-buttons {
  width: 100%;
  margin-left: 0.875rem;
  margin-top: 0.875rem;
  margin-bottom: -0.5rem;

  a {
    display: inline-block;
  }
}

.view-all-std-tests-btn-container {
  .button:hover {
    background: linear-gradient(#1d5681, #256ca2) !important;
  }
}
