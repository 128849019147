.layout-subcategories {
  padding: 0 3rem;
  text-align: center;

  @media #{$large-up} {
    .center-content {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .subcategory-container {
    padding: 0.5rem !important;
    float: left !important;

    .panel {
      background-color: $white;
      border-radius: 10px;
      border: none;
      padding: 1rem 1.5rem;
      box-shadow: 2px 3px 9px 1px rgba(0, 0, 0, 0.12);

      div.container-subcategory-title {
        min-height: 78px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        height: 60px;
      }

      p {
        padding: 0;
        margin: 0;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        color: $darkest-blue;
      }
    }
  }
}
