// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// # HealthLabs.com
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//
// ## Description:
// This is the main stylesheet that gets compiled into a
// centralized CSS file for the entire website.
//
// ## Notes:
// - There should be no CSS in this file, it's only purpose
//   is to import all stylesheets in the proper order.
// - The structure is based on ITCSS (http://itcss.io/)
// - We abide by CSS Guidelines (http://cssguidelin.es/)
//   which explains "High-level advice and guidelines for
//   writing sane, manageable, scalable CSS".
// - Imports are typically ordered alphabetically unless
//   due to CSS specificity (that's clearly labeled)
//
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Settings:
// Global variables, config switches.
@import 'settings.variables';
@import 'settings.foundation';
@import 'foundation-sites/scss/foundation';
$fa-font-path: '@fortawesome/fontawesome-free/webfonts';
@import '@fortawesome/fontawesome-free/scss/fontawesome';
@import '@fortawesome/fontawesome-free/scss/solid';
@import '@fortawesome/fontawesome-free/scss/brands';
$findalab-nav-color: $primary-color;

// Tools:
// Default mixins, placeholders and functions.
@import 'tools.mixins';
// @import "tools.sprite";

// Components:
// Designed components, chunks of UI.
@import 'components.steps';
@import 'components.popular';
@import 'components.login';
@import 'components.account';
@import 'components.doctorinfo';
@import 'components.warning-modal-message';
@import 'collapsible';
@import 'styles';
@import 'floating-header';
@import 'cookie-popup';
@import 'shopper-approved';
@import 'cta';
@import 'layout_subcategories';

// Browser Support
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
@import '_browser-support';

app-find-a-lab .in-home-booking-container {
  @import '../../../ng-elements/node_modules/bulma/bulma';
  @import '../../../ng-elements/node_modules/bulma-calendar/src/sass/index';
}
