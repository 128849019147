// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// # Mixins
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//
// ## Description:
// Groups of CSS Declarations that can be reused throughout the project.
//
//
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Grid
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

@mixin clearfix {
  overflow: auto;
  zoom: 1;
}

// Typography
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// White Text Shadow
@mixin text-shadow {
  text-shadow: 1px 1px 3px $white;
}
