// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// # Browser Support
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//
// ## Description:
// CSS Trumps and overrides for browser support
//
//
// ## Table of Contents:
// 1.
//
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// No Flexbox
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.no-flexbox {
  & .header__logo {
    float: left;
  }
  & .header__buttons {
    float: right;
  }
  & .map-panel__box {
    max-width: 400px;
    margin: 100px auto;
  }
}

// No Linear Gradients
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.no-cssgradients .footer {
  background: $primary-color;
}

// No Columns
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.no-csscolumns {
  & .footer__menu--categories > li {
    float: right;
    width: 50%;
  }
  & .popup__list > li {
    float: right;
    width: 25%;
  }
  & .popup__list {
    overflow: auto;
    zoom: 1;
  }
}
