// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Popular
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//
// Best selling / popular test stand out on the website.
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Variables
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

$popular-color: #fded35;

// Test Category Page
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.popular {
  background: $popular-color;
  font-weight: bold;
  display: inline-block;
  letter-spacing: 1px;
  padding: 7px 11px;
  color: #1d558e;
  font-size: 55%;
  margin-top: -1%;
  border-radius: 5px;
  a & {
    color: #1d558e;
    font-size: 70%;
    padding: 3px 5px;
  }
}
