// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Login
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// .login {
// padding-top: 20vh;
// }

.login__logo {
  text-align: center;
  margin-bottom: 1rem;
}

.login__logoImage {
  margin: 0 auto 1rem;
}

.login__backlink {
  margin-top: 1rem;
  margin-left: 1rem;
  display: block;
}

.login__recaptcha {
  margin-bottom: 1rem;
}

.login__link {
  display: block;
  margin-top: 10px;
}
