// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Call to Action (CTA)
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.cta {
  background-image: url('../images/bg-cta.png');
  background-size: cover;
  background-position: bottom right;
  background-color: #0e0e0f;
  padding: $column-gutter;

  h2 {
    color: #000000;
    font-size: 3.3125rem;
  }

  h4 {
    color: #02955f;
    font-size: 2rem;
  }

  .cta__description p {
    color: #000000;
    margin-top: 2rem;
    width: 400px;
  }
}

.cta__button {
  font-weight: bold;
  font-size: 1.5rem;
  padding: 10px;
}

.cta__title {
  margin-bottom: 4rem;
}

.cta__description {
  margin-bottom: 2rem;

  p {
    font-size: 25px;
  }
}

.cta__secondary-button {
  color: $white;
  display: block;
  margin-top: 1rem;
  text-decoration: underline;
  font-weight: bold;

  &:hover {
    color: darken($white, 10%);
  }
}

@media only screen and (max-width: 600px) {
  .cta {
    background-image: url('../images/bg-cta-mobile.png');

    h2 {
      font-size: 2.7125rem;
      width: 300px;
    }

    .cta__description {
      width: 175px;
      margin-top: 2rem;

      p {
        font-size: 1.05rem;
        width: 250px;
      }
    }
  }
}
