// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Steps
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//
// Located below the main menu to display MyFastLab's
// process.
//
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Variables
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

$steps-color: $primary-color !default;

// Block
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.steps {
  background: #f6e896;
  box-shadow: inset 0px 0px 0px 4px #eddf93;
  border-left: 1px solid #f9efb5;
  border-right: 1px solid #f9efb5;
  border-bottom: 1px solid $grey;
  font-size: 90px;

  &.with-coupon {
    font-size: 73px;
  }

  @media #{$medium-up} {
    display: flex;
    flex-direction: column;
    height: 8rem;
  }

  @media #{$small-only} {
    height: 13.2rem;

    &.with-coupon {
      font-size: 85px;
    }
  }
}

// Elements
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.steps__container {
  box-shadow: inset 0px 5px 0px 0px #eddf93;
  padding-top: 5px;
  &.without-coupon {
    padding-top: 0;
    box-shadow: none;
    height: 100%;
  }

  @media #{$medium-up} {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  @media #{$small-only} {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    height: 70%;
  }
}

.steps__step {
  padding: 0 0;

  @media #{$medium-up} {
    background: url('../images/arrow.png') no-repeat right center;
  }
}

.steps__step--first {
  @extend .steps__step;
}

.steps__step--last {
  @extend .steps__step;

  @media #{$medium-up} {
    background: none;
  }
}

.steps__number {
  $steps-number-size: 0.95em;
  color: #fd9741;
  font-size: $steps-number-size;
  line-height: $steps-number-size;
  float: left;
  width: $steps-number-size;
  height: $steps-number-size;
  text-align: center;
  font-weight: 300;

  @media #{$small-only} {
    font-size: 0.42em;
    flex: 0 0 9%;
  }
}

.steps__title {
  color: $steps-color;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.22em;
  padding-top: 5px;
  @include text-shadow;

  @media #{$small-only} {
    font-size: 0.23em;
    flex: 0 0 82%;
    padding-top: 0;
    padding-left: 0.3em;
  }
}
.steps__row--mobile {
  @media #{$small-only} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.steps__icon--mobile {
  display: none;

  @media #{$small-only} {
    display: flex;
    justify-content: center;
    flex: 0 0 9%;
    color: $steps-color;
    @include text-shadow;

    font-size: 0.3em;
  }
}

.steps__icon {
  color: $steps-color;
  float: left;
  font-size: 0.4em;
  padding-right: 10px;
  padding-top: 2px;
  @include text-shadow;

  @media #{$small-only} {
    display: none;
  }
}

.steps__description {
  font-size: 0.15em;
  max-width: 300px;
  line-height: 1.2;
  color: darken($primary-color, 10%);
  margin-top: 0.3rem;
  @include text-shadow;

  @media #{$small-only} {
    display: none;
  }
}
