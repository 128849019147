// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Account
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Login Panel
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.panel__title--login {
  max-width: 345px;
}

.panel--login {
  max-width: 345px;
}

// Title
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.account__title {
  margin: 0;
  line-height: 1;
}

.account__subtitle {
  font-size: rem-calc(26);
  font-style: italic;
  font-weight: bold;
  line-height: 1.5;
  margin-top: rem-calc(10);
}

.account__loginStatus {
  text-align: right;
  margin-bottom: rem-calc(10);
}

// Tabs
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.account__tabs__title {
  border: 1px solid $iron;
  margin-left: -1px;

  &.active {
    border-bottom: 3px solid $info-color;
  }
}

// Order Boxes
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.order__header {
  background: $silver;
  padding: rem-calc(10);
  border: 1px solid $iron;
  border-radius: $global-radius $global-radius 0 0;
}

.order__header__desc {
  text-transform: uppercase;
  font-size: rem-calc(14);
}

.order__header__data {
  font-weight: bold;
}

.order__body {
  border: 1px solid $iron;
  padding: rem-calc(20) rem-calc(10);
  margin-top: -1px;
  margin-bottom: $column-gutter;
  border-radius: 0 0 $global-radius $global-radius;
}

.order__body__status {
  font-weight: bold;
  font-size: rem-calc(30);
}

.order__body__status--avaliable {
  @extend .order__body__status;
  color: $success-color;
}

.order__body__status--notavaliable {
  @extend .order__body__status;
  color: $info-color;
}

.order__body__status--partial {
  @extend .order__body__status;
  color: $warning-color;
}

.order__body__status--cancelled {
  @extend .order__body__status;
  color: $alert-color;
}

.order__body__heading {
  font-weight: bold;
  font-size: rem-calc(18);
}

.order__body__heading--emphasis {
  @extend .order__body__heading;
  color: $info-color;
}

.order__body__list {
  padding: 0;
  margin-left: rem-calc(20);
  margin-bottom: rem-calc(15);
  & li {
    float: left;
    margin-left: rem-calc(30);
    padding: 0;
  }
}

.order__body__buttons {
  padding-top: rem-calc(15);
}

.order__body__button--reorder {
  @include button($padding: $button-sml, $bg: $alert-color, $radius: true);
}

// Messages
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.message__empty {
  font-size: rem-calc(30);
  text-align: center;
}

.message__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.message__list__item {
  border: 1px solid $iron;
  margin-top: -1px;
  padding: rem-calc(10);
  background: $silver;

  &.js-active {
    border-right: 3px solid $info-color;
    background: $white;
  }
}

.message__list__subject {
  font-weight: bold;
}

.message__list__meta {
  font-style: italic;
  font-size: rem-calc(14);
}

.message__list__delete {
  text-decoration: underline;
  color: $alert-color;
}

.message {
  display: none;
  &.js-active {
    display: block;
  }
}

.message__header {
  @extend .order__header;
}

.message__subject {
  font-weight: bold;
}

.message__date {
  font-style: italic;
}

.message__body {
  @extend .order__body;
}
